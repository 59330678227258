<app-mediego-confirmation-dialog [dialogTitle]="'COMMON.MAILER_COMPATIBILITY.TITLE' | translate:{featureName: data.featureLabel}"
                                 icon="mediego"
                                 [acceptLabel]="''"
                                 [declineLabel]="'SHARED.BUTTONS.CLOSE' | translate">

  <section fxLayout="row">

    <div fxFlex>
      <h3 class="device"><mat-icon>computer</mat-icon> {{ 'COMMON.MAILER_COMPATIBILITY.DEVICE.DESKTOP' | translate }}</h3>
      <mat-list>
        <mat-list-item *ngFor="let compatibility of featureSupportDesktop">

          <mat-icon *ngIf="compatibility.isSupported; else notSupported" class="supported">check_circle</mat-icon>
          <ng-template #notSupported>
            <mat-icon color="warn">dangerous</mat-icon>
          </ng-template>
          {{ compatibility.name }}
        </mat-list-item>
      </mat-list>
    </div>

    <div fxFlex>
      <h3 class="device"><mat-icon>smartphone</mat-icon> {{ 'COMMON.MAILER_COMPATIBILITY.DEVICE.MOBILE' | translate }}</h3>
      <mat-list class="middle-list">
        <mat-list-item *ngFor="let compatibility of featureSupportMobile">

          <mat-icon *ngIf="compatibility.isSupported; else notSupported" class="supported">check_circle</mat-icon>
          <ng-template #notSupported>
            <mat-icon color="warn">dangerous</mat-icon>
          </ng-template>
          {{ compatibility.name }}
        </mat-list-item>
      </mat-list>
    </div>

    <div fxFlex>
      <h3 class="device"><mat-icon>public</mat-icon> {{ 'COMMON.MAILER_COMPATIBILITY.DEVICE.WEB' | translate }}</h3>
      <mat-list>
        <mat-list-item *ngFor="let compatibility of featureSupportWeb">

          <mat-icon *ngIf="compatibility.isSupported; else notSupported" class="supported">check_circle</mat-icon>
          <ng-template #notSupported>
            <mat-icon color="warn">dangerous</mat-icon>
          </ng-template>
          {{ compatibility.name }}
        </mat-list-item>
      </mat-list>
    </div>

  </section>

  <mat-divider></mat-divider>

  <aside fxLayout="row wrap" fxLayoutAlign="center center">
    <h3 fxFlex="100%" class="email-providers">{{ 'COMMON.MAILER_COMPATIBILITY.PROVIDERS.TITLE' | translate }}</h3>
    <img fxFlex="50%" src="assets/img/email_providers.png" alt="" title="" />
    <p fxFlex="100%" class="email-providers--legend">{{ 'COMMON.MAILER_COMPATIBILITY.PROVIDERS.SOURCE' | translate }}</p>
  </aside>
</app-mediego-confirmation-dialog>
