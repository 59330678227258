import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Compatibility {
  name: string;
  isSupported: boolean;
}

@Component({
    selector: 'app-mediego-mailer-compatibility-card',
    templateUrl: './mailer-compatibility-card.component.html',
    styleUrls: ['./mailer-compatibility-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MailerCompatibilityCardComponent implements OnInit {

  featureSupportDesktop: Compatibility[] = [];
  featureSupportMobile: Compatibility[] = [];
  featureSupportWeb: Compatibility[] = [];

  static convertTupleToCompatibility(tuples: any[]) {
    return tuples.map(tuple => ({
      name: tuple[0],
      isSupported: tuple[1]
    }));
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: { featureName: string; featureLabel: string; }) {}

  ngOnInit() {

    switch (this.data.featureName) {
      case 'hover':
        this.setFeatureSupportTablesForHover();
        break;
      default:
        throw new Error('compatibility card component needs a feature to display compatibility tables');
    }

  }

  setFeatureSupportTablesForHover() {
    this.featureSupportDesktop = MailerCompatibilityCardComponent.convertTupleToCompatibility([
      ['AOL Desktop', true],
      ['Apple Mail 10', true],
      ['IBM Notes 9', false],
      ['Outlook 2000-2003', true],
      ['Outlook 2007-2016', false],
      ['Outlook Express', true],
      ['Outlook for Mac', true],
      ['Postbox', true],
      ['Thunderbird', true],
      ['Windows 10 Mail', false],
      ['Windows Live Mail', true]
    ]);

    this.featureSupportMobile = MailerCompatibilityCardComponent.convertTupleToCompatibility([
      ['Android 4.2.2 Mail', true],
      ['Android 4.4.4 Mail', true],
      ['AOL Alto Android app', true],
      ['AOL Alto iOS app', false],
      ['BlackBerry', true],
      ['Gmail Android app', true],
      ['Gmail Android app IMAP', false],
      ['Gmail iOS app', false],
      ['Gmail mobile webmail', false],
      ['iOS 10 Mail', true],
      ['iOS 11 Mail', true],
      ['Outlook Android app', true],
      ['Outlook iOS app', true],
      ['Windows Phone 8 Mail', true],
      ['Yahoo! Mail Android appp', true],
      ['Yahoo! Mail iOS app', true]
    ]);

    this.featureSupportWeb = MailerCompatibilityCardComponent.convertTupleToCompatibility([
      ['AOL Mail', true],
      ['G Suite', true],
      ['Gmail', true],
      ['Outlook.com', false],
      ['Yahoo! Mail', true]
    ]);
  }


}
